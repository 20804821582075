exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-departemen-page-js": () => import("./../../../src/templates/departemen-page.js" /* webpackChunkName: "component---src-templates-departemen-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-kepsek-page-js": () => import("./../../../src/templates/kepsek-page.js" /* webpackChunkName: "component---src-templates-kepsek-page-js" */),
  "component---src-templates-medsos-page-js": () => import("./../../../src/templates/medsos-page.js" /* webpackChunkName: "component---src-templates-medsos-page-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-projects-page-js": () => import("./../../../src/templates/projects-page.js" /* webpackChunkName: "component---src-templates-projects-page-js" */),
  "component---src-templates-ulasan-page-js": () => import("./../../../src/templates/ulasan-page.js" /* webpackChunkName: "component---src-templates-ulasan-page-js" */),
  "component---src-templates-visimisi-page-js": () => import("./../../../src/templates/visimisi-page.js" /* webpackChunkName: "component---src-templates-visimisi-page-js" */)
}

